.terms-of-use__container {
  width: 800px;
  margin: 0px auto;
  padding-top: 15px;
  color: #eaecef;
}

.terms-of-use__container h2,
.terms-of-use__container h3 {
  color: #00bf79;
  margin-top: 25px;
  margin-bottom: 15px;
}

.terms-of-use__container > div.text-justify > ul.ctia-list > li {
  color: #eaecef;
}

.terms-of-use__logo--inverted {
  width: 89px;
  height: 100px;
}

/* low resolution, 480px and below */
@media only screen and (max-width: 480px) {
  .terms {
    width: 100%;
    max-width: 450px;
  }
}

/* medium resolution, 481px to 1023px */
@media only screen and (min-width: 481px) {
  .terms {
    width: 100%;
    max-width: 480px;
  }
}

/* high resolution, 1024px and higher */
@media only screen and (min-width: 1024px) {
  .terms {
    width: 100%;
    max-width: 1000px;
  }
}
