ul.ctia-list li,
ol.ctia-list li {
  color: #374960;
  letter-spacing: 0;
  line-height: 1.45;
  position: relative;
  margin-bottom: 5px;
}

ul.ctia-list,
ol.ctia-list {
  list-style: none;
  padding: 0;
}

.ctia-list.form-field-list {
  padding-top: 7px;
}

ul.ctia-list ul,
ul.ctia-list ol,
ol.ctia-list ul,
ol.ctia-list ol {
  margin-top: 10px;
  list-style: none;
}

ul.ctia-list ol,
ol.ctia-list ol {
  padding-left: 26px;
}

ul.ctia-list li {
  padding-left: 26px;
}

ul.ctia-list li::before {
  content: "";
  display: block;
  position: absolute;
  background: #00bf79;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 7px;
  left: 0;
}

ul.ctia-list li li::before {
  width: 7px;
  height: 7px;
}

ol.ctia-list {
  counter-reset: section;
}

ol.ctia-list li::before {
  counter-increment: section;
  content: counter(section) ". ";
  color: #00bf79;
  font-size: 18px;
  font-weight: 500;
}

ol.ctia-list li li::before {
  font-size: 17px;
}
