h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  color: #034872;
}

a {
  cursor: pointer;
  color: #034872;
}

#root {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
  width: 100%;
}

.nav-logout-link {
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.nav-logout-link:hover {
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

#request-nav-tabs .nav-pills > li,
#enter-test-results .nav-pills > li {
  float: none;
}

.nav-pills > li {
  float: left;
  padding-right: 5px;
}

.datepicker-block {
  width: 200px;
}

.datepicker-block:first-of-type {
  margin-bottom: 10px;
}

.datepicker-block:last-of-type {
  margin-bottom: 0;
}

#ctia-textinput input {
  height: 34px;
}

.table-hover > tbody > tr:hover {
  background-color: #ebebeb;
}

.react-datepicker-popper {
  z-index: 5;
}

/* footer svg {
  height: 100%;
  width: 100%;
} */

.rc-select-selection {
  border-radius: 4px;
}

.has-error .rc-select-selection--single {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 30px;
}

.voltage-divider {
  display: inline-block;
  margin-right: 15px;
}

#request-filter {
  padding-top: 15px;
}

.ctia-breadcrumbs {
  padding: 8px 0;
}

.no-margin {
  margin: 0;
}

.requiredField {
  font-weight: bold;
  color: #dc3545;
}

/* low resolution, 480px and below */
@media only screen and (max-width: 480px) {
  html,
  body {
    /* background-color: #202737; */
    background-color: #01283f;
    font-size: 11px;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    color: #01283f;
  }

  body.gray {
    background-color: #eee;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h1,
  h2,
  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .navbar-nav > li > a {
    font-size: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 0;
  }

  table {
    font-size: 9px;
  }

  #request-filter > .col-xs-6 {
    display: none;
  }

  #request-filter > .col-xs-2 {
    width: 33.33%;
  }

  .form-group select.form-control {
    height: 20px;
    line-height: 30px;
  }

  .form-group .form-control {
    font-size: 10px;
    height: 20px;
    padding: 0 3px;
  }

  .btn-xs,
  .btn-group-xs > .btn {
    font-size: 9px;
    line-height: 1.2;
  }

  .btn-sm,
  .btn-group-sm > .btn {
    padding: 5px 10px;
    font-size: 10px;
    line-height: 1.2;
    border-radius: 3px;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    padding: 5px 8px;
    font-size: 12px;
    line-height: 1.3333333;
    border-radius: 6px;
  }

  #xs-userinfo {
    background: #666;
    border-radius: 3px;
    display: block;
    margin: 2px;
    padding: 5px;
    position: absolute;
    right: 0;
    z-index: 5;
  }

  #nav-logout,
  #nav-userinfo {
    display: none;
  }

  .navbar {
    margin-bottom: 0;
  }

  .navbar-custom .navbar-nav > li > a {
    color: #fff;
    display: inline-block;
    font-size: 10px;
    margin: 0 !important;
    opacity: 0.7;
  }

  .navbar-nav {
    margin-top: 25px;
  }

  .home-description {
    padding: 10px 0;
    line-height: 1.3;
  }

  .logo > img,
  .logo > svg {
    width: 55%;
  }

  .modal-footer button {
    font-size: 11px;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    font-size: 14px;
  }

  #upload-tips {
    font-size: 9px;
    padding-left: 15px;
  }

  .alert {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }

  h4.modal-title {
    font-size: 19px;
  }

  .container-fluid {
    padding: 0 15px;
    margin: 0 auto;
  }

  /* suggestor */
  .rc-select,
  li.rc-select-dropdown-menu-item {
    font-size: 10px;
  }

  .rc-select-selection--single {
    height: 20px;
    line-height: 20px;
  }

  .rc-select-selection--single .rc-select-selection__rendered {
    margin: 0 0 0 5px;
    line-height: 20px;
  }

  .rc-select-arrow {
    height: 18px;
  }

  .action-column-width {
    width: 150px;
  }

  .file-status-info {
    width: 125px;
    font-size: 10px;
  }

  .cert-card {
    margin: 0 15px;
    max-width: 500px;
  }

  .home-description__logo {
    display: none;
  }

  .home-description__text {
    display: inline-block;
    width: 100%;
  }
}

/* medium resolution, 481px to 1023px */
@media only screen and (min-width: 481px) {
  html,
  body {
    /* background-color: #202737; */
    background-color: #01283f;
    font-size: 12px;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    color: #01283f;
  }

  body.gray {
    background-color: #eee;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h1,
  h2,
  h3 {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .navbar-nav > li > a {
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 10px;
  }

  #xs-userinfo {
    display: block;
  }

  #nav-logout,
  #nav-userinfo {
    display: none;
  }

  #xs-userinfo {
    background: #666;
    border-radius: 3px;
    display: block;
    margin: 2px;
    padding: 5px;
    position: absolute;
    right: 0;
    z-index: 5;
  }

  .navbar {
    margin-bottom: 0;
  }

  .navbar-custom .navbar-nav > li > a {
    color: #fff;
    display: block;
    font-size: 12px;
    margin: 3px !important;
    opacity: 0.7;
  }

  .navbar-nav {
    margin-top: 30px;
  }

  .home-description {
    padding: 15px 0;
    line-height: 1.35;
  }

  #request-filter > .col-xs-6 {
    display: none;
  }

  #request-filter > .col-xs-2 {
    width: 33.33%;
  }

  .form-group select.form-control {
    height: 26px;
    line-height: 30px;
  }

  .form-group .form-control {
    font-size: 13px;
    height: 26px;
    padding: 3px 6px;
  }

  .modal-footer button {
    font-size: 12px;
  }

  .btn-xs,
  .btn-group-xs > .btn {
    font-size: 10px;
    line-height: 1.2;
  }

  .btn-sm,
  .btn-group-sm > .btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.2;
    border-radius: 3px;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    font-size: 14px;
  }

  #upload-tips {
    font-size: 11px;
    padding-left: 15px;
  }

  .alert {
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 4px;
  }

  h4.modal-title {
    font-size: 22px;
  }

  .container-fluid {
    padding: 0 15px;
    margin: 0 auto;
  }

  /* suggestor */
  .rc-select {
    font-size: 12px;
  }

  .rc-select-selection--single {
    height: 26px;
    line-height: 26px;
  }

  .rc-select-selection--single .rc-select-selection__rendered {
    margin: 0 0 0 5px;
    line-height: 26px;
  }

  li.rc-select-dropdown-menu-item {
    font-size: 12px;
  }

  .rc-select-arrow {
    height: 22px;
  }

  .action-column-width {
    width: 250px;
  }

  .file-status-info {
    width: 145px;
    font-size: 12px;
  }

  .cert-card {
    margin: auto;
    max-width: 500px;
  }

  .home-description__logo {
    display: inline-block;
    vertical-align: top;
    width: 20%;
  }

  .home-description__text {
    display: inline-block;
    width: 80%;
  }
}

/* high resolution, 1024px and higher */
@media only screen and (min-width: 1024px) {
  html,
  body {
    height: 100%;
    background-color: #01283f;
  }

  html {
    font-size: 16px;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #01283f;
  }

  body.gray {
    background-color: #eee;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h1,
  h2,
  h3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  input[type="file"] {
    width: 100%;
  }

  p,
  ul {
    font-size: 16px;
  }

  #xs-userinfo {
    display: none;
  }

  #nav-logout,
  #nav-userinfo {
    display: block;
  }

  .navbar {
    margin-bottom: 0;
  }

  .navbar-custom .navbar-nav > li > a {
    color: #fff;
    display: block;
    font-size: 15px;
    margin: 10px !important;
    opacity: 0.7;
  }

  .navbar-nav {
    margin-top: 0;
  }

  .navbar-nav > li > a {
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
  }

  .home-description {
    padding: 15px 0;
    line-height: 1.45;
  }

  #request-filter > .col-xs-6 {
    display: block;
    float: left;
    width: 50%;
  }

  #request-filter > .col-xs-2 {
    width: 16.66666667%;
  }

  .form-group select.form-control {
    height: 34px;
    line-height: 30px;
  }

  .form-group .form-control {
    font-size: 15px;
    height: 34px;
    padding: 6px 12px;
  }

  .form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }

  .modal-footer button {
    font-size: 14px;
  }

  .btn-xs,
  .btn-group-xs > .btn {
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.5;
    padding: 1px 5px;
  }

  .btn-sm,
  .btn-group-sm > .btn {
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.43;
    padding: 6px 12px;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    border-radius: 6px;
    font-size: 18px;
    line-height: 1.34;
    padding: 10px 16px;
  }

  .link-group span:not(:first-child) {
    padding-left: 10px;
  }

  #upload-tips {
    font-size: 12px;
    padding-left: 15px;
  }

  .alert {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
  }

  h4.modal-title {
    font-size: 25px;
  }

  .container-fluid {
    padding: 0 15px 0 0;
    margin: 0 auto;
  }

  /* suggestor */
  .rc-select {
    font-size: 14px;
  }

  .rc-select-selection--single {
    height: 34px;
    line-height: 30px;
  }

  .rc-select-selection--single .rc-select-selection__rendered {
    margin: 0 0 0 10px;
    line-height: 30px;
  }

  li.rc-select-dropdown-menu-item {
    font-size: 14px;
  }

  .rc-select-arrow {
    height: 30px;
  }

  .action-column-width {
    width: 320px;
  }

  .well {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
  }

  .file-status-info {
    width: 165px;
    font-size: 14px;
  }

  .cert-card {
    width: 100%;
    margin: auto;
    max-width: 900px;
  }

  .home-description__logo {
    display: inline-block;
    vertical-align: top;
    width: 15%;
  }

  .home-description__text {
    display: inline-block;
    width: 85%;
  }
}

.sticky-block h4 {
  margin-top: 0;
}

@media (min-width: 992px) {
  .sticky-block {
    position: sticky;
    top: 10px;
  }
}

.reqListContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ctia-modal {
  overflow: auto;
}
