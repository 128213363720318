table.ctia-table {
  border-collapse: collapse;
  background: #fff;
  border: 5px solid #e3e4e6;
  border-width: 1px 3px 3px;
  margin-top: 15px;
}

table.ctia-table > tbody > tr > td,
table.ctia-table > thead > tr > th {
  font-size: 16px;
  color: #374960;
  line-height: 1.65;
  padding: 5px 10px;
  border: none;
  vertical-align: middle;
}

table.ctia-table > thead > tr > th {
  background: #034872;
  color: #ffffff;
  font-weight: 700;
  text-align: left;
  padding: 10px;
}

table.ctia-table > tbody > tr {
  border-bottom: 2px solid #f2f2f2;
}

table.ctia-table div.form-group {
  margin: 10px 0;
}

table.ctia-table ul.ctia-list {
  margin: 5px 10px;
}

@media only screen and (max-width: 480px) {
  table.ctia-table > tbody > tr > td,
  table.ctia-table > thead > tr > th {
    font-size: 11px;
  }
}

@media only screen and (min-width: 481px) {
  table.ctia-table > tbody > tr > td,
  table.ctia-table > thead > tr > th {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1024px) {
  table.ctia-table > tbody > tr > td,
  table.ctia-table > thead > tr > th {
    font-size: 16px;
  }
}
