/**************************************************************************
Environment ribbon
**************************************************************************/
div.environmentRibbon
{
	background:     #fff;
	/*bottom:         115px;*/
	box-shadow:     0px 0px 4px 4px rgba( 0, 0, 0, 0.25 );
	color:          #000;
	font-family:    "Times New Roman", Times, serif;
	font-size:      18px;
	font-size:      1.125rem;
	height:         32px;
	left: 			-65px;
	line-height:    32px;
	position:       fixed;
	/*right:          -30px;*/
	text-align:     center;
	text-transform: capitalize;
	top: 			-35px;
	width:          208px;
	z-index:        10000;

	-webkit-transform-origin: bottom right;
	    -ms-transform-origin: bottom right;
	        transform-origin: bottom right;

	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}

div.environmentRibbon:after
{
	border:   2px solid #000;
	content:  '';
	height:   24px;
	left:     2px;
	position: absolute;
	top:      2px;
	width:    200px;
}

/* Environment Ribbons */
div.environmentRibbon.environment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    top: 0px;
    left: 0px;
    width: 350px;
    height: auto;
    padding: 10px;
    line-height: 1;
    transform: rotate(-45deg) translate(-111px, -23px);
    transform-origin: center;
}

div.environmentRibbon.environment:after {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
}

div.environmentRibbon.development { background: #ebf1dd; color: #9bbb59; }
div.environmentRibbon.staging     { background: #7D9AEC; color: #1442E8; }
div.environmentRibbon.production  { background: #f2dcdb; color: #c0504d; }

div.environmentRibbon.development:after { border: 2px solid #9bbb59; }
div.environmentRibbon.staging:after     { border: 2px solid #1442E8; }
div.environmentRibbon.production:after  { border: 2px solid #c0504d; }

div.environmentRibbon { background: #fdeada; color: #ff5a00; }
div.environmentRibbon:after { border: 2px solid #ef6b00; }