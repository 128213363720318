.payments-atl-link {
  padding: 5px 0 10px 0;
}

.billToForm input[type="text"] {
  padding: 0;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 0 10px;
  width: 100%;
}

.billToForm b {
  width: 100px;
}

.billToForm__field {
  display: flex;
  align-items: center;
  align-content: center;
  padding-bottom: 5px;
}

.editIvcTop {
  padding-bottom: 5px;
}

.editIvcTop input[type="text"] {
  padding: 0;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 0;
  width: 150px;
}

table.atl-invoice {
  border: 1px solid #aaa;
  width: 100%;
}

table.atl-invoice thead {
  background-color: #034872;
  color: white;
  font-weight: bold;
}

table.atl-invoice td {
  border: 1px solid #aaa;
}

table.atl-invoice td {
  width: 50%;
}

.h1-div {
  color: #034872;
  font-size: 36px;
  margin-bottom: 15px;
}

.dashed {
  text-decoration: underline;
  text-decoration-style: dotted;
}
