/* Checkboxes, radio buttons */
.ctia-form input[type="checkbox"],
.ctia-form input[type="radio"] {
  position: absolute;
  width: 18px;
  height: 18px;
  opacity: 0;
}

.ctia-form input[type="checkbox"] + label,
.ctia-form input[type="radio"] + label {
  position: relative;
  transition: all 0.25s ease;
  padding-left: 30px;
  padding-top: 2px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.ctia-form input[type="checkbox"] + label.bold,
.ctia-form input[type="radio"] + label.bold {
  font-weight: 700;
}

.ctia-form input[type="checkbox"] + label::before,
.ctia-form input[type="radio"] + label::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
  transition: inherit;
  box-sizing: border-box;
}

.ctia-form input[type="checkbox"]:hover + label::before,
.ctia-form input[type="checkbox"]:focus + label::before,
.ctia-form input[type="radio"]:hover + label::before,
.ctia-form input[type="radio"]:focus + label::before {
  border-color: #00bf79;
}

.ctia-form input[type="checkbox"]:checked + label::before,
.ctia-form input[type="radio"]:checked + label::before {
  background-color: #00bf79;
  border-color: #00bf79;
}

.ctia-form input[type="checkbox"][disabled]:hover + label::before,
.ctia-form input[type="checkbox"][disabled]:focus + label::before,
.ctia-form input[type="radio"][disabled]:hover + label::before,
.ctia-form input[type="radio"][disabled]:focus + label::before {
  border-color: #bfbfbf;
}

.ctia-form input[type="checkbox"][disabled] + label,
.ctia-form input[type="radio"][disabled] + label {
  opacity: 0.5;
  pointer-events: none;
}

.ctia-form input[type="checkbox"] + label::before {
  border-radius: 4px;
  border: 2px solid #bfbfbf;
}

.ctia-form input[type="checkbox"]:checked + label::before {
  background: #00bf79 url(/assets/svg/icon-checkbox.svg) no-repeat 50% 50%;
}

.ctia-form input[type="radio"] + label::before {
  border-radius: 100%;
  border: 2px solid #dbdbdb;
}

.ctia-form table.ctia-form__list, ul.ctia-form__list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 5px;
}

.ctia-form ul.ctia-form__list li, table.ctia-form__list tr {
  margin-bottom: 10px;
  padding-left: 0;
}

.ctia-form ul.ctia-form__list li::before, table.ctia-form__list tr::before{
  display: none;
}

@media only screen and (max-width: 480px) {
  .ctia-form input[type="checkbox"] + label,
  .ctia-form input[type="radio"] + label {
    font-size: 11px;
  }
}

@media only screen and (min-width: 481px) {
  .ctia-form input[type="checkbox"] + label,
  .ctia-form input[type="radio"] + label {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1024px) {
  .ctia-form input[type="checkbox"] + label,
  .ctia-form input[type="radio"] + label {
    font-size: 16px;
  }
}
