#fullpage-loader.fpl-loader {
  color: #d7ca9e;
}

#fullpage-loader__spinner.fpl-spinner {
  border-color: #5c6670;
  border-top-color: #78cdd1;
}

.Loaderlink .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}