.jumbo-block {
  border-radius: 5px;
  background-color: #eee !important;
  border: 1px solid #ddd;
  word-wrap: break-word;
}

.jumbo-block-icon {
  cursor: pointer;
}

.jumbo-block-header {
  padding: 0 10px 15px 10px;
}

/* low resolution, 480px and below */
@media only screen and (max-width: 480px) {
  .jumbo-block-icon {
    font-size: 18px;
  }
}

/* medium resolution, 481px to 1023px */
@media only screen and (min-width: 481px) {
  .jumbo-block-icon {
    font-size: 20px;
  }
}

/* high resolution, 1024px and higher */
@media only screen and (min-width: 1024px) {
  .jumbo-block-icon {
    font-size: 22px;
  }
}

.ctia-jumbo {
  border: 2px solid #ccc;
  border-top: 5px solid #00bf79;
  border-radius: 2px;
  margin-bottom: 25px;
  height: 400px;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  padding: 85px 45px;

  background-color: #fff;
  background-image: url(/assets/svg/icon-database.svg);
  background-position: 50% 30px;
  background-repeat: no-repeat;
  background-size: 35px 44px;
}

.ctia-jumbo h3 {
  font-size: 18px;
  font-weight: 700;
  color: #034872;
  line-height: 1.45;
}

.ctia-jumbo__button {
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
}

.ctia-jumbo.logo-users {
  background-image: url(/assets/svg/icon-group.svg);
  background-size: 66px 44px;
}

.ctia-jumbo.logo-reports {
  background-image: url(/assets/svg/icon-pdf.svg);
  background-size: 36px 44px;
}

.ctia-jumbo.logo-payments {
  background-image: url(/assets/svg/icon-pay.svg);
  background-size: 66px 66px;
}

.ctia-jumbo.logo-org {
  background-image: url(/assets/svg/icon-ctia-select.svg);
  background-size: 55px 55px;
}

.box-with-shadow:hover {
  -webkit-box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 1);
  -moz-box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 1);
  box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 1);
  transition: box-shadow 0.4s;
}

.ctia-jumbo__descr {
  width: 100%;
}
