:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.mr-auto {
  margin-right: auto !important;
}

.certnav {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.certnav a,
.certnav a:active,
.certnav a:link,
.certnav a:hover {
  text-decoration: none;
  padding: 10px 0;
  margin: 0 10px;
}

.nav-link.active {
  border-bottom: 2px solid #78cdd1;
}

.certnav > .container,
.certnav > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.certnav-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.certnav-brand:hover,
.certnav-brand:focus {
  text-decoration: none;
}

.certnav-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.certnav-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.certnav-nav .dropdown-menu {
  position: static;
  float: none;
}

.certnav-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.certnav-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.certnav-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.certnav-toggler:hover,
.certnav-toggler:focus {
  text-decoration: none;
}

.certnav-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.certnav-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .certnav-expand-sm > .container,
  .certnav-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .certnav-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .certnav-expand-sm .certnav-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .certnav-expand-sm .certnav-nav .dropdown-menu {
    position: absolute;
  }
  .certnav-expand-sm .certnav-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .certnav-expand-sm > .container,
  .certnav-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .certnav-expand-sm .certnav-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .certnav-expand-sm .certnav-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .certnav-expand-md > .container,
  .certnav-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .certnav-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .certnav-expand-md .certnav-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .certnav-expand-md .certnav-nav .dropdown-menu {
    position: absolute;
  }
  .certnav-expand-md .certnav-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .certnav-expand-md > .container,
  .certnav-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .certnav-expand-md .certnav-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .certnav-expand-md .certnav-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .certnav-expand-lg > .container,
  .certnav-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .certnav-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .certnav-expand-lg .certnav-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .certnav-expand-lg .certnav-nav .dropdown-menu {
    position: absolute;
  }
  .certnav-expand-lg .certnav-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .certnav-expand-lg > .container,
  .certnav-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .certnav-expand-lg .certnav-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .certnav-expand-lg .certnav-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .certnav-expand-xl > .container,
  .certnav-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .certnav-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .certnav-expand-xl .certnav-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .certnav-expand-xl .certnav-nav .dropdown-menu {
    position: absolute;
  }
  .certnav-expand-xl .certnav-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .certnav-expand-xl > .container,
  .certnav-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .certnav-expand-xl .certnav-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .certnav-expand-xl .certnav-toggler {
    display: none;
  }
}

.certnav-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.certnav-expand > .container,
.certnav-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.certnav-expand .certnav-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.certnav-expand .certnav-nav .dropdown-menu {
  position: absolute;
}

.certnav-expand .certnav-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.certnav-expand > .container,
.certnav-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.certnav-expand .certnav-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.certnav-expand .certnav-toggler {
  display: none;
}

.certnav-light .certnav-brand {
  color: rgba(0, 0, 0, 0.9);
}

.certnav-light .certnav-brand:hover,
.certnav-light .certnav-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.certnav-light .certnav-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.certnav-light .certnav-nav .nav-link:hover,
.certnav-light .certnav-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.certnav-light .certnav-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.certnav-light .certnav-nav .show > .nav-link,
.certnav-light .certnav-nav .active > .nav-link,
.certnav-light .certnav-nav .nav-link.show,
.certnav-light .certnav-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.certnav-light .certnav-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.certnav-light .certnav-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.certnav-light .certnav-text {
  color: rgba(0, 0, 0, 0.5);
}

.certnav-light .certnav-text a {
  color: rgba(0, 0, 0, 0.9);
}

.certnav-light .certnav-text a:hover,
.certnav-light .certnav-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.certnav-dark .certnav-brand {
  color: #fff;
}

.certnav-dark .certnav-brand:hover,
.certnav-dark .certnav-brand:focus {
  color: #fff;
}

.certnav-dark .certnav-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.certnav-dark .certnav-nav .nav-link:hover,
.certnav-dark .certnav-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.certnav-dark .certnav-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.certnav-dark .certnav-nav .show > .nav-link,
.certnav-dark .certnav-nav .active > .nav-link,
.certnav-dark .certnav-nav .nav-link.show,
.certnav-dark .certnav-nav .nav-link.active {
  color: #fff;
}

.certnav-dark .certnav-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.certnav-dark .certnav-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.certnav-dark .certnav-text {
  color: rgba(255, 255, 255, 0.5);
}

.certnav-dark .certnav-text a {
  color: #fff;
}

.certnav-dark .certnav-text a:hover,
.certnav-dark .certnav-text a:focus {
  color: #fff;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:first-child {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.dropdown-item:last-child {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  .certnav {
    display: none;
  }
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

@media only screen and (max-width: 1120px) {
  .certnav-expand .certnav-nav .nav-link {
    padding-right: 0.1rem;
    padding-left: 0.1rem;
  }

  .app-header {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1121px) {
  .certnav-expand .certnav-nav .nav-link {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }

  .app-header {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1300px) {
  .certnav-expand .certnav-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .app-header {
    font-size: 14px;
  }
}
