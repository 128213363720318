div#maint-logo-wrapper {
  height: 0px;
  position: absolute;
  overflow: visible;
  display: block;
  width: 100%;
  left: 0px;
  top: 50%;
}

div#maint-bubble-wrapper {
  height: 0px;
  position: absolute;
  overflow: visible;
  display: block;
  width: 100%;
  left: 0px;
  top: 50px;
}

/* low resolution, 480px and below */
@media only screen and (max-width: 480px) {
  div#maint-logo-container {
    overflow: auto;
    position: absolute;
    left: 50%;
    margin-left: -165px; /* half of width */
    width: 330px; /* width of div */
    height: 250px; /* height of div */
    top: -125px; /* half of height */
    text-align: center;
  }

  div#maint-logo-container svg {
    width: 75%;
  }
}

/* medium resolution, 481px to 1023px */
@media only screen and (min-width: 481px) {
  div#maint-logo-container {
    overflow: auto;
    position: absolute;
    left: 50%;
    margin-left: -250px; /* half of width */
    width: 500px; /* width of div */
    height: 250px; /* height of div */
    top: -125px; /* half of height */
    text-align: center;
  }

  div#maint-bubble-container {
    overflow: auto;
    position: absolute;
    left: 50%;
    margin-left: -240px; /* half of width */
    width: 480px; /* width of div */
    height: 150px; /* height of div */
  }
}

/* high resolution, 1024px and higher */
@media only screen and (min-width: 1024px) {
  div#maint-logo-container {
    overflow: auto;
    position: absolute;
    left: 50%;
    margin-left: -250px; /* half of width */
    width: 500px; /* width of div */
    height: 250px; /* height of div */
    top: -125px; /* half of height */
    text-align: center;
  }

  div#maint-bubble-container {
    overflow: auto;
    position: absolute;
    left: 50%;
    margin-left: -300px; /* half of width */
    width: 600px; /* width of div */
    height: 150px; /* height of div */
  }

  div#maint-bubble-container p {
    font-size: 14px;
  }
}
