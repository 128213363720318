/* Buttons */
.btn-success,
.btn-success[disabled]:hover {
  color: #fff;
  background-color: #00bf79;
  border-color: #00bf79;
}

.btn-success:hover {
  color: #fff;
  background-color: #008c59;
  border-color: #008c59;
}

.btn-warning,
.btn-warning[disabled]:hover {
  color: #fff;
  background-color: #ee7700;
  border-color: #dd6f00;
}

.btn-warning:hover {
  color: #fff;
  background-color: #dd6f00;
  border-color: #dd6f00;
}

.btn-info,
.btn-info[disabled]:hover {
  color: #fff;
  background-color: #0396a6;
  border-color: #0396a6;
}

.btn-info:hover {
  color: #fff;
  background-color: #026974;
  border-color: #026974;
}

.btn-primary,
.btn-primary[disabled]:hover {
  color: #fff;
  background-color: #5c6670;
  border-color: #545e67;
}

.btn-primary:hover {
  color: #fff;
  background-color: #545e67;
  border-color: #545e67;
}

.btn-success[disabled],
.btn-warning[disabled],
.btn-info[disabled],
.btn-primary[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.btn-group .active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

/* Alerts */
.alert-success {
  color: #fff;
  background-color: #00bf79;
  border-color: #009961;
}

.alert-warning {
  color: #994d00;
  background-color: #fdb;
  border-color: #ffc388;
}

.alert-info {
  /* color: #0396a6; */
  color: #eaecef;
  /* background-color: #cdf9fe; */
  background-color: #034872;
  /* border-color: #05cbe1; */
  border-color: #01283f;
}

.alert-primary {
  color: #454d54;
  background-color: #dadde0;
  border-color: #bec3c9;
}

/* Nav pills */
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #5c6670;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #f6f6f6;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  font-weight: bold;
}

/* Panels */
.panel-primary {
  border-color: #9edbde;
}

.panel-primary > .panel-heading {
  color: #03717d;
  background-color: #0396a6;
  border-color: #025a64;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #9edbde;
}

.panel-default {
  border-color: #ccc;
}

.panel-default > .panel-heading {
  color: #555;
  background-color: #ededed;
  border-color: #999;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ccc;
  background-color: #f6f6f6;
}

.panel-heading {
  cursor: pointer;
}

/* .panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
} */

/* Switcher */
.rc-switch-checked {
  border: 1px solid #008051;
  background-color: #00bf79;
}

.rc-switch-disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.rc-switch-disabled:after {
  background: #fff;
  -webkit-animation-name: none;
  animation-name: none;
  cursor: no-drop;
}

/* InfoBar (WellInfo) */
.well {
  background-color: #ededed;
}

.wellSpan .value {
  color: #0396a6;
}

/* rc-collapse */
.rc-collapse {
  background-color: #ededed;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
}

.rc-collapse-header {
  font-weight: bold;
}

/* btn-shadowzz */
.btn-shadow {
  box-shadow: 3px 3px #ccc;
  transition: 0.3s;
}

.btn-shadow:hover {
  box-shadow: 3px 3px #999;
  transition: 0.3s;
}

/* Card Custom Header */
.ctia-header-bg {
  background-color: #ededed;
  cursor: pointer;
}

.ctia-header-bg .badge {
  margin-right: 15px;
}

/* Checklist */
.list-group-item-success {
  color: white;
  background-color: #00bf79;
}

.list-group-item-danger {
  color: #333;
  background-color: #eaecef;
}

.list-group-item {
  border: 1px solid #ddd;
}

/* CTIA Glow Link */
.glow-link {
  text-decoration: underline;
  -webkit-text-decoration-color: #00bf79;
  text-decoration-color: #00bf79;
  color: inherit;
}

.glow-link:hover,
.glow-link:focus {
  color: #00bf79;
}
