.arrow-button {
  color: #fff;
  display: inline-block;
  cursor: pointer;
}

.arrow-button__link {
  background: #00bf79;
  color: #fff;
}

.arrow-button__link:hover,
.arrow-button__link:focus {
  background-color: #008c59;
}

.arrow-button__text {
  display: inline-block;
  vertical-align: middle;
}

.arrow-button__svg {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 10px;
}

.arrow-button__svg.reflect-arrow {
  margin-left: 0;
  margin-right: 10px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.arrow-button__link {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.48px;
  font-weight: 700;
  transition: all 0.25s ease;
  border: none;
  display: inline-flex;
  align-items: center;
  padding: 15px 15px;
  box-shadow: none;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.arrow-button__link .fill {
  transition: inherit;
  fill: currentColor;
}

.arrow-button__link:hover,
.arrow-button__link:visited,
.arrow-button__link:focus,
.arrow-button__link:active {
  color: inherit;
  text-decoration: none;
}

.arrow-button__link[disabled] {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.arrow-button__link.has-blue-background-color {
  border-color: #034872;
  background-color: #034872;
}

.arrow-button__link.has-blue-background-color:hover,
.arrow-button__link.has-blue-background-color:focus {
  border-color: #022940;
  background-color: #022940;
}

.arrow-button__link.has-blue-background-color:not(.has-text-color) {
  color: #ffffff;
}

.arrow-button__link.has-blue-background-color:not(.has-text-color):hover,
.arrow-button__link.has-blue-background-color:not(.has-text-color):active,
.arrow-button__link.has-blue-background-color:not(.has-text-color):visited {
  color: #ffffff;
}

.arrow-button__link.has-navy-background-color {
  border-color: #01283f;
  background-color: #01283f;
}

.arrow-button__link.has-navy-background-color:hover,
.arrow-button__link.has-navy-background-color:focus {
  border-color: #00080d;
  background-color: #00080d;
}

.arrow-button__link.has-navy-background-color:not(.has-text-color) {
  color: #ffffff;
}

.arrow-button__link.has-navy-background-color:not(.has-text-color):hover,
.arrow-button__link.has-navy-background-color:not(.has-text-color):active,
.arrow-button__link.has-navy-background-color:not(.has-text-color):visited {
  color: #ffffff;
}

.arrow-button__link.has-green-background-color {
  border-color: #00bf79;
  background-color: #00bf79;
}

.arrow-button__link.has-green-background-color:hover,
.arrow-button__link.has-green-background-color:focus {
  border-color: #008c59;
  background-color: #008c59;
}

.arrow-button__link.has-green-background-color:not(.has-text-color) {
  color: #ffffff;
}

.arrow-button__link.has-green-background-color:not(.has-text-color):hover,
.arrow-button__link.has-green-background-color:not(.has-text-color):active,
.arrow-button__link.has-green-background-color:not(.has-text-color):visited {
  color: #ffffff;
}

.arrow-button__link.has-light-blue-background-color {
  border-color: #0396a6;
  background-color: #0396a6;
}

.arrow-button__link.has-light-blue-background-color:hover,
.arrow-button__link.has-light-blue-background-color:focus {
  border-color: #026974;
  background-color: #026974;
}

.arrow-button__link.has-light-blue-background-color:not(.has-text-color) {
  color: #ffffff;
}

.arrow-button__link.has-light-blue-background-color:not(.has-text-color):hover,
.arrow-button__link.has-light-blue-background-color:not(.has-text-color):active,
.arrow-button__link.has-light-blue-background-color:not(.has-text-color):visited {
  color: #ffffff;
}

.arrow-button__link.has-white-background-color {
  border-color: #ffffff;
  background-color: #ffffff;
}

.arrow-button__link.has-white-background-color:hover,
.arrow-button__link.has-white-background-color:focus {
  border-color: #e6e6e6;
  background-color: #00bf79;
}

.arrow-button__link.has-white-background-color:not(.has-text-color) {
  color: #01283f;
}

.arrow-button__link.has-white-background-color:not(.has-text-color):hover,
.arrow-button__link.has-white-background-color:not(.has-text-color):active,
.arrow-button__link.has-white-background-color:not(.has-text-color):visited {
  color: #fff;
}

.arrow-button__link.has-gray-background-color {
  border-color: #eaecef;
  background-color: #eaecef;
}

.arrow-button__link.has-gray-background-color:hover,
.arrow-button__link.has-gray-background-color:focus {
  border-color: #cdd2d9;
  background-color: #cdd2d9;
}

.arrow-button__link.has-gray-background-color:not(.has-text-color) {
  color: #374960;
}

.arrow-button__link.has-gray-background-color:not(.has-text-color):hover,
.arrow-button__link.has-gray-background-color:not(.has-text-color):active,
.arrow-button__link.has-gray-background-color:not(.has-text-color):visited {
  color: #374960;
}

.arrow-button__link.has-black-background-color {
  border-color: #000;
  background-color: #000;
}

.arrow-button__link.has-black-background-color:hover,
.arrow-button__link.has-black-background-color:focus {
  border-color: black;
  background-color: black;
}

.arrow-button__link.has-black-background-color:not(.has-text-color) {
  color: #ffffff;
}

.arrow-button__link.has-black-background-color:not(.has-text-color):hover,
.arrow-button__link.has-black-background-color:not(.has-text-color):active,
.arrow-button__link.has-black-background-color:not(.has-text-color):visited {
  color: #ffffff;
}

.arrow-button.is-style-outline .wp-block-button {
  border-color: #034872;
}

.arrow-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
}

.wp-block[data-type="core/button"]
  .arrow-button.is-style-outline
  .wp-block-button__link {
  background-color: transparent !important;
}

.arrow-button.is-style-outline .wp-block-button__link:not(.has-background) {
  border-color: #034872;
}

.arrow-button.is-style-outline
  .wp-block-button__link:not(.has-background):hover {
  border-color: #022940;
}

.arrow-button.is-style-outline .wp-block-button__link:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-blue-background-color {
  background-color: transparent !important;
  border-color: #034872;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-blue-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-blue-background-color:focus {
  border-color: #022940;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-blue-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-navy-background-color {
  background-color: transparent !important;
  border-color: #01283f;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-navy-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-navy-background-color:focus {
  border-color: #00080d;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-navy-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-green-background-color {
  background-color: transparent !important;
  border-color: #00bf79;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-green-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-green-background-color:focus {
  border-color: #008c59;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-green-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-lightblue-background-color {
  background-color: transparent !important;
  border-color: #0396a6;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-lightblue-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-lightblue-background-color:focus {
  border-color: #026974;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-lightblue-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-light-blue-background-color {
  background-color: transparent !important;
  border-color: #0396a6;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-light-blue-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-light-blue-background-color:focus {
  border-color: #026974;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-light-blue-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-background-color {
  background-color: transparent !important;
  border-color: #eaecef;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-background-color:focus {
  border-color: #cdd2d9;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-white-background-color {
  background-color: transparent !important;
  border-color: #ffffff;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-white-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-white-background-color:focus {
  border-color: #e6e6e6;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-white-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-light-background-color {
  background-color: transparent !important;
  border-color: #eaecef;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-light-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-light-background-color:focus {
  border-color: #cdd2d9;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-gray-light-background-color:not(.has-text-color) {
  color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-black-background-color {
  background-color: transparent !important;
  border-color: #000;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-black-background-color:hover,
.arrow-button.is-style-outline
  .wp-block-button__link.has-black-background-color:focus {
  border-color: black;
}

.arrow-button.is-style-outline
  .wp-block-button__link.has-black-background-color:not(.has-text-color) {
  color: #000;
}
