@import "ctia-form.css";
@import "ctia-table.css";
@import "ctia-lists.css";

.grayBlock {
  background-color: #f7f7f7;
  border: 1px solid #d7d7d9;
}

.bold {
  font-weight: bold;
}

.green {
  /* color: #77bc1f; */
  color: #00bf79;
}

.profile-link:link,
.profile-link:active,
.profile-link:hover,
.profile-link:visited {
  color: #00bf79;
}

.red {
  color: #a94442;
}

.teal {
  color: #78cdd1;
}

.dark-teal {
  /* color: #287377; */
  color: #0396a6;
}

.gray {
  color: #5c6670;
}

.dashedLink {
  border-bottom: 1px dashed #d7d7d9;
  cursor: pointer;
  margin-right: 20px;
}

#request-edit-tabs .nav-tabs,
#tabs-with-dropdown .nav-tabs {
  margin-bottom: 15px;
}

.curtain {
  opacity: 0.1;
  pointer-events: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.app-button-link,
.app-button-link:hover {
  text-decoration: none;
}

.popover-content p {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

h3.popover-title {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 120%;
}

.btn-margin10-both {
  margin: 0 10px;
}

.block-logo-middle {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -142px;
}

.input-file-disabled {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.result-info-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon-left-offset {
  margin-left: 10px;
}

.icon-right-offset {
  margin-right: 10px;
}

.button-right-offset {
  margin-right: 15px;
}

.button-top-offset {
  margin-top: 10px;
}

.block-top-offset {
  margin-top: 25px;
}

.block-bottom-offset {
  padding-bottom: 15px;
}

.block-left-offset {
  padding-left: 25px;
}

.button-v-offset {
  margin: 15px 0;
}

.option-disabled {
  background-color: #eee;
  color: #999;
}

.cert-card {
  /* width: 100%; */
  max-width: 1200px;
  margin: auto;
  padding: 10px 50px;
  box-sizing: border-box;
  border-bottom: 2px solid #ccc;

  background-image: url(/assets/svg/icon-database.svg);
  background-position: 5px 25px;
  background-repeat: no-repeat;
  background-size: 24px 30px;
}

.cert-card:last-of-type {
  border-bottom: 0;
}

.cert-card__description {
  margin: 20px 0;
  color: #374960;
  font-size: 16px;
  line-height: 1.75;
}

.cert-card__links {
  margin-top: 30px;
}

.cert-card__link {
  padding-left: 45px;
  font-size: 20px;
  cursor: pointer;
}

.ant-drawer-title {
  color: #034872;
  font-size: 20px;
  font-weight: 700;
}

.userLinks button {
  font-size: large;
}

.form-warn {
  font-style: italic;
}

.static-form-text {
  color: #999;
}

/* Animation for icons rotating */
.rotateMe {
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
}

.button-no-border,
.button-no-border:hover,
.button-no-border:active {
  padding: 0;
  border: none;
  background: none;
  outline: none;
}

.button-no-border:hover,
.button-no-border:active {
  color: #00bf79;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*** End rotating animation ***/

.breadcrumb a:hover,
.breadcrumb a:active {
  color: #00bf79;
}
