.canvasLogoContainer {
  position: relative;
}

.canvasLogoContainer__IoT {
    margin: -6px 0;
}

.canvasText {
  position: absolute;
  bottom: 5px;
  left: 90px;
  width: 150px;
  height: 15px;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.canvasText__CATL {
    bottom: 4px;
    color: black;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    left: 96px;
    position: absolute;
    width: 150px;
}

.canvasText_PTCRB {
  position: absolute;
  bottom: 4px;
  left: 74px;
  width: 150px;
  height: 15px;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}

.canvasText_IoT {
  position: absolute;
  bottom: 11px;
  left: 98px;
  width: 150px;
  height: 15px;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}

.canvasText_ASC {
    bottom: -16px;
    left: 106px;
}

#edit-center-form hr {
    margin: 8px;
}