.temp-pass__container {
  width: 600px;
  margin: 0px auto;
  padding-top: 15px;
  color: #333;
}

.temp-pass__container h2,
.temp-pass__container h3 {
  color: #034872;
  margin-top: 25px;
  margin-bottom: 15px;
}

.temp-pass__container > div.text-justify > ul.ctia-list > li {
  color: #333;
}

.temp-pass__logo--inverted {
  width: 89px;
  height: 100px;
}
